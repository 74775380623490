/* Font Imports */
@font-face {
  font-family: "Director-Bold";
  src: url("../../../public/fonts/Director-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Director-Light";
  src: url("../../../public/fonts/Director-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Director-Regular";
  src: url("../../../public/fonts/Director-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Director-Variables";
  src: url("../../../public/fonts/Director-Variable.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Latitude-Regular";
  src: url("../../../public/fonts/Latitude-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Switzer-Extralight";
  src: url("../../../public/fonts/Switzer-Extralight.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Switzer-Light";
  src: url("../../../public/fonts/Switzer-Light.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

:root {
  /* Default font color */
  --default-font: "Switzer-Extralight";
  --default-color: #eff2f4;
  --default-font-color: #14213d;
  --contact-font-color: #14213d;

  /* Default font sizes */
  --name-font-size: 31px;
  --contact-font-size: 10px;

  /* Breakpoint 1 font sizes */
  --bp1-name-font-size: 31px;
  --bp1-contact-font-size: 14px;
  --bp1-padding-top: 30px;
  /* Breakpoint 2 font sizes */
  --bp2-name-font-size: 38px;
  --bp2-contact-font-size: 16px;
  --bp2-padding-top: 30px;
  /* Breakpoint 3 font sizes */
  --bp3-name-font-size: 38px;
  --bp3-contact-font-size: 16px;
  --bp3-padding-top: 30px;
  /* Breakpoint 4 font sizes */
  --bp4-name-font-size: 48px;
  --bp4-contact-font-size: 16px;
  --bp4-padding-top: 0px;
  /* Breakpoint 5 font sizes */
  --bp5-name-font-size: 48px;
  --bp5-contact-font-size: 16px;
  --bp5-padding-top: 20px;
  /* Breakpoint 6 font sizes */
  --bp6-name-font-size: 48px;
  --bp6-contact-font-size: 20px;
  --bp6-padding-top: 15px;
  /* Breakpoint 7 font sizes */
  --bp7-name-font-size: 60px;
  --bp7-contact-font-size: 24px;
  --bp7-padding-top: 60px;
}
.contact-container {
  /* z-index: 1; */
  pointer-events: none;
  position: absolute;
  top: 42vh;
  height: fit-content;
}

.name {
  color: var(--default-font-color);
  font-family: var(--default-font);
  font-size: var(--name-font-size);
  line-height: 98%;
  transition: font-size 0.3s ease; /* Animated transition */
  padding-top: var(--bp1-padding-top);
  margin-bottom: 0;
  pointer-events: none;
  text-transform: uppercase;
  letter-spacing: 1.2px;
}

.contact-link {
  color: var(--contact-font-color);
  text-decoration: none; /* Removes the underline */
  font-size: var(--contact-font-size);
  transition: font-size 0.3s ease; /* Animated transition */
  /* pointer-events: none; */
}

.contact {
  pointer-events: all;
}

.name,
.contact {
  opacity: 0; /* Initial state of the elements is invisible */
  transition: opacity 1.5s ease 1s; /* The transition effect will affect the opacity property, and will take 1.5 seconds to execute with a delay of 1 second */
}
.intercept {
  pointer-events: none;
}
/* This class will be added dynamically using JavaScript to trigger the fade-in effect */
.fade-in {
  opacity: 1;
}

/* Breakpoint 1: 375 x 667 */
@media screen and (min-width: 375px) and (max-width: 413px) {
  .name {
    font-size: var(--bp1-name-font-size);
  }

  .contact-link {
    font-size: var(--bp1-contact-font-size);
  }
}

/* Breakpoint 2: 414 x 896 */
@media screen and (min-width: 414px) and (max-width: 767px) {
  .name {
    font-size: var(--bp2-name-font-size);
    padding-top: var(--bp2-padding-top);
  }

  .contact-link {
    font-size: var(--bp2-contact-font-size);
  }
}

/* Breakpoint 3: 768 x 1024 */
@media screen and (min-width: 768px) and (max-width: 833px) {
  .name {
    font-size: var(--bp3-name-font-size);
    padding-top: var(--bp3-padding-top);
  }

  .contact-link {
    font-size: var(--bp3-contact-font-size);
  }
}

/* Breakpoint 4: 834 x 1112 */
@media screen and (min-width: 834px) and (max-width: 1023px) {
  .name {
    font-size: var(--bp4-name-font-size);
    padding-top: var(--bp4-padding-top);
  }

  .contact-link {
    font-size: var(--bp4-contact-font-size);
  }
}

/* Breakpoint 5: 1024 x 768 */
@media screen and (min-width: 1024px) and (max-width: 1111px) {
  .name {
    font-size: var(--bp5-name-font-size);
    padding-top: var(--bp5-padding-top);
  }

  .contact {
    margin-top: 10px;
  }

  .contact-link {
    font-size: var(--bp5-contact-font-size);
  }
}

@media screen and (min-width: 1112px) and (max-width: 1919px) {
  .name {
    font-size: var(--bp6-name-font-size);
    padding-top: var(--bp6-padding-top);
  }

  .contact-link {
    font-size: var(--bp6-contact-font-size);
  }
}

/* Breakpoint 7: 1920 x 1440 */
@media screen and (min-width: 1920px) {
  .name {
    font-size: var(--bp7-name-font-size);
    padding-top: var(--bp7-padding-top);
  }

  .contact-link {
    font-size: var(--bp7-contact-font-size);
  }
}
