.loadingScreen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #e5e5e5;
}

.loadingText {
  font-size: 2em;
  display: flex;
}

.loadingText span {
  opacity: 1;
  color: black;
  font-size: 1rem;
}
